module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.gstatic.com"],"interval":300,"timeout":30000,"web":[{"name":"Homemade Apple","file":"https://fonts.googleapis.com/css2?family=Homemade+Apple"},{"name":"Cormorant Garamond","file":"https://fonts.googleapis.com/css2?family=Cormorant+Garamond"},{"name":"Lato","file":"https://fonts.googleapis.com/css2?family=Lato"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vacanze Romane szkoła języka włoskiego","short_name":"Vacanze Romane","start_url":"/","background_color":"#f8f5f1","theme_color":"#f8f5f1","display":"minimal-ui","icon":"src/assets/images/logo-vacanze.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"06abbdf1a10787e0ce404ddd033efe5d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
