exports.components = {
  "component---src-layouts-lesson-js": () => import("./../../../src/layouts/lesson.js" /* webpackChunkName: "component---src-layouts-lesson-js" */),
  "component---src-layouts-post-js": () => import("./../../../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-layouts-product-free-summary-js": () => import("./../../../src/layouts/product-free-summary.js" /* webpackChunkName: "component---src-layouts-product-free-summary-js" */),
  "component---src-layouts-product-js": () => import("./../../../src/layouts/product.js" /* webpackChunkName: "component---src-layouts-product-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blad-js": () => import("./../../../src/pages/blad.js" /* webpackChunkName: "component---src-pages-blad-js" */),
  "component---src-pages-blad-platnosci-js": () => import("./../../../src/pages/blad-platnosci.js" /* webpackChunkName: "component---src-pages-blad-platnosci-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dziekujemy-js": () => import("./../../../src/pages/dziekujemy.js" /* webpackChunkName: "component---src-pages-dziekujemy-js" */),
  "component---src-pages-in-italiano-js": () => import("./../../../src/pages/in-italiano.js" /* webpackChunkName: "component---src-pages-in-italiano-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-platnosc-przelewem-js": () => import("./../../../src/pages/platnosc-przelewem.js" /* webpackChunkName: "component---src-pages-platnosc-przelewem-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-sklep-js": () => import("./../../../src/pages/sklep.js" /* webpackChunkName: "component---src-pages-sklep-js" */),
  "component---src-pages-szukaj-js": () => import("./../../../src/pages/szukaj.js" /* webpackChunkName: "component---src-pages-szukaj-js" */),
  "component---src-pages-wloski-od-zera-js": () => import("./../../../src/pages/wloski-od-zera.js" /* webpackChunkName: "component---src-pages-wloski-od-zera-js" */),
  "component---src-pages-zamowienie-js": () => import("./../../../src/pages/zamowienie.js" /* webpackChunkName: "component---src-pages-zamowienie-js" */)
}

